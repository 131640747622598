export const RobolLogo = ({ width, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 807.309 807.309"
      style={{ marginTop: "5px" }}
    >
      <g
        id="Group_135"
        data-name="Group 135"
        transform="translate(1516.708 3097.691)"
      >
        <g id="SvgjsG2316" transform="translate(-1516.708 -3097.691)">
          <rect
            id="Rectangle_88"
            data-name="Rectangle 88"
            width="807.309"
            height="807.309"
            rx="89.701"
            fill="#fff"
          />
        </g>
        <g id="SvgjsG2317" transform="translate(-1419.511 -3008.385)">
          <path
            id="Path_130"
            data-name="Path 130"
            d="M491.252,336.957V125.926L379.163,62.271h-111.4L435.9,157.754V368.785Z"
            fill="#036"
          />
          <path
            id="Path_131"
            data-name="Path 131"
            d="M200.652,456.657,379.163,560.442h-.692l2.768,2.076L496.1,496.1l55.352-96.175-168.825,98.25L200.652,393Z"
            fill="#036"
          />
          <path
            id="Path_132"
            data-name="Path 132"
            d="M423.445,394.385h.692L324.5,451.121l54.66,31.828L560.442,377.088V243.55L505.09,147.376V344.568Z"
            fill="#036"
          />
          <path
            id="Path_133"
            data-name="Path 133"
            d="M422.061,165.365,240.783,60.2,125.926,126.618,70.574,223.485l171.592-98.942,83.028,48.433h-1.384l98.25,56.736Z"
            fill="#036"
          />
          <path
            id="Path_134"
            data-name="Path 134"
            d="M243.55,140.456,62.271,246.318V379.163l55.352,96.175V278.145l81.645-48.433h0L298.9,172.284Z"
            fill="#036"
          />
          <path
            id="Path_135"
            data-name="Path 135"
            d="M131.462,285.756V496.787L243.55,560.442H355.639L186.814,464.959V253.929Z"
            fill="#036"
          />
        </g>
      </g>
    </svg>
  );
};
export const CrossIcon = ({ fill = "#fff", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" {...props}>
      <path
        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
        fill={fill}
      />
    </svg>
  );
};

export const MinimizeIcon = ({ fill = "#fff", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" {...props}>
      <path
        d="M44 344h296c13.3 0 24-10.7 24-24s-10.7-24-24-24H44c-13.3 0-24 10.7-24 24s10.7 24 24 24z"
        fill={"#fff"}
      />
    </svg>
  );
};

export const RobotSolidIcon = ({ fill = "#black", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 498.171 502.322"
  >
    <g id="SvgjsG2317" transform="translate(-62.271 -60.196)">
      <path
        id="Path_130"
        data-name="Path 130"
        d="M491.252,336.957V125.926L379.163,62.271h-111.4L435.9,157.754V368.785Z"
        fill="#fff"
      />
      <path
        id="Path_131"
        data-name="Path 131"
        d="M200.652,456.657,379.163,560.442h-.692l2.768,2.076L496.1,496.1l55.352-96.175-168.825,98.25L200.652,393Z"
        fill="#fff"
      />
      <path
        id="Path_132"
        data-name="Path 132"
        d="M423.445,394.385h.692L324.5,451.121l54.66,31.828L560.442,377.088V243.55L505.09,147.376V344.568Z"
        fill="#fff"
      />
      <path
        id="Path_133"
        data-name="Path 133"
        d="M422.061,165.365,240.783,60.2,125.926,126.618,70.574,223.485l171.592-98.942,83.028,48.433h-1.384l98.25,56.736Z"
        fill="#fff"
      />
      <path
        id="Path_134"
        data-name="Path 134"
        d="M243.55,140.456,62.271,246.318V379.163l55.352,96.175V278.145l81.645-48.433h0L298.9,172.284Z"
        fill="#fff"
      />
      <path
        id="Path_135"
        data-name="Path 135"
        d="M131.462,285.756V496.787L243.55,560.442H355.639L186.814,464.959V253.929Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export const SendIcon = () => {
  return (
    <svg
      viewBox="1496 193 57 54"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Group-9-Copy-3"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(1523.000000, 220.000000) rotate(-270.000000) translate(-1523.000000, -220.000000) translate(1499.000000, 193.000000)"
      >
        <path
          d="M5.42994667,44.5306122 L16.5955554,44.5306122 L21.049938,20.423658 C21.6518463,17.1661523 26.3121212,17.1441362 26.9447801,20.3958097 L31.6405465,44.5306122 L42.5313185,44.5306122 L23.9806326,7.0871633 L5.42994667,44.5306122 Z M22.0420732,48.0757124 C21.779222,49.4982538 20.5386331,50.5306122 19.0920112,50.5306122 L1.59009899,50.5306122 C-1.20169244,50.5306122 -2.87079654,47.7697069 -1.64625638,45.2980459 L20.8461928,-0.101616237 C22.1967178,-2.8275701 25.7710778,-2.81438868 27.1150723,-0.101616237 L49.6075215,45.2980459 C5.08414042,47.7885641 49.1422456,50.5306122 46.3613062,50.5306122 L29.1679835,50.5306122 C27.7320366,50.5306122 26.4974445,49.5130766 26.2232033,48.1035608 L24.0760553,37.0678766 L22.0420732,48.0757124 Z"
          id="sendicon"
          fill="#96AAB4"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const SwitchConvoIcon = ({
  fill = "#000",
  width = "24",
  height = "24",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 512"
    className="switch-icon"
    {...props}
  >
    <path
      d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"
      fill={fill}
    />
  </svg>
);

export const MessageEnvelopIcon = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      fill={color}
    >
      {/* Font Awesome Free 6.5.1 by @fontawesome - 
      https://fontawesome.com License - 
      https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. */}
      <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
    </svg>
  );
};

export const WhatsAppIcon = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width={width}
    height={height}
    fill={color}
  >
    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
  </svg>
);
