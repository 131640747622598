// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-in {
  animation: slideIn 0.2s ease-in-out forwards;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.hidden {
  display: none;
}

.visible {
  display: flex;
  flex-wrap: wrap;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/AvailableUsers.scss"],"names":[],"mappings":"AACA;EACI,4CAAA;AAAJ;;AAGE;EACE;IACE,UAAA;IACA,4BAAA;EAAJ;EAEE;IACE,UAAA;IACA,wBAAA;EAAJ;AACF;AAGE;EACE,aAAA;AADJ;;AAIE;EACE,aAAA;EACA,eAAA;AADJ;;AAIE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AADJ","sourcesContent":["\r\n.slide-in {\r\n    animation: slideIn 0.2s ease-in-out forwards;\r\n  }\r\n  \r\n  @keyframes slideIn {\r\n    0% {\r\n      opacity: 0;\r\n      transform: translateY(-20px);\r\n    }\r\n    100% {\r\n      opacity: 1;\r\n      transform: translateY(0);\r\n    }\r\n  }\r\n  \r\n  .hidden {\r\n    display: none;\r\n  }\r\n  \r\n  .visible {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n  }\r\n\r\n  .close-icon {\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n    cursor: pointer;\r\n  }\r\n\r\n\r\n  \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
