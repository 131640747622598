import React, { useEffect, useState } from "react";
import "../css/AvailableUsers.scss";
import { Spinner } from "react-bootstrap";
import { CrossIcon, SwitchConvoIcon } from "../helpers/SvgIcons";
import avatarPlaceHolder from "./avatar-placeholder.png";
import {
  forwardOption,
  getUserInfoBySession,
} from "../services/WidgetServices";
import { EnumForwarding } from "./EnumForwarding";
import RobotIcon from "../utils/icons/robot-solid.svg";

const AvailableUsers = ({
  availableUsers,
  companyName,
  secondaryColor,
  setForwardingRequested,
  onChange,
  setIsForwarded,
  forwardingRequested,
  avatarUrl,
  employeeName,
  department,
  sessionId,
  forwardStatus,
  companyEmail,
  company_icon,
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      if (sessionId) {
        try {
          const userData = await getUserInfoBySession(sessionId);
          setSelectedUser(userData);
        } catch (error) {
          console.error("Error fetching user data:", error.message);
        }
      }
    };

    fetchUserData();
  }, [sessionId]);

  useEffect(() => {
    if (forwardingRequested && !confirmed) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [forwardingRequested, confirmed]);

  useEffect(() => {
    if (!loading && !showCard && forwardingRequested) {
      setShowCard(true);
    }
  }, [loading, showCard, forwardingRequested]);

  const handleUserChange = async (user) => {
    const confirmation = window.confirm(
      "Czy na pewno chcesz przełączyć użytkownika?"
    );
    if (confirmation) {
      setLoading(true);
      try {
        await forwardOption(
          sessionId,
          user.email,
          EnumForwarding.FORWARD_REQUESTED
        );
        setSelectedUser(user);
        setConfirmed(true);
        setIsForwarded(true);
        setLoading(false);
        onChange(user);
      } catch (error) {
        console.error("Error while forwarding:", error);
        setLoading(false);
      }
    }
  };

  const handleClose = async () => {
    if (selectedUser) {
      await forwardOption(
        sessionId,
        selectedUser.email,
        EnumForwarding.NOT_FORWARDED
      );
    }
    setIsForwarded(false);
    setForwardingRequested(false);
    setSelectedUser("");
    setConfirmed(false);
    setShowCard(false);
    setLoading(false);
  };

  const handleCancelForward = async () => {
    if (selectedUser) {
      await forwardOption(
        sessionId,
        selectedUser.email,
        EnumForwarding.NOT_FORWARDED
      );
    }
    setSelectedUser("");
    setIsForwarded(false);
    setForwardingRequested(false);
    setConfirmed(false);
    setShowCard(false);
    setLoading(false);
  };

  const handleSwitchConvo = () => {
    setSelectedUser("");
    setForwardingRequested(true);
    setShowCard(false);
  };

  console.log("forwardStatus", forwardStatus);
  return (
    <>
      <div className="header-user">
        {!forwardingRequested &&
          !confirmed &&
          forwardStatus !== EnumForwarding.IS_FORWARDED && (
            <>
              <div
                className={`header-left ${
                  !forwardingRequested ? "slide-in" : "slide-out"
                }`}
              >
                <div className="avatar-header">
                  <span className="bot-avatar  gradient-avatar">
                    <img
                      src={avatarUrl}
                      alt="robotIcon"
                      className="robot-icon"
                    />
                  </span>
                </div>
                {employeeName && (
                  <span className="employee-name">
                    <b>{employeeName}</b> <br />
                    {department && (
                      <span className="department">{department}</span>
                    )}
                  </span>
                )}
              </div>
              {forwardStatus === EnumForwarding.NOT_FORWARDED && (
                <div className="header-right" title="Medewerker spreken">
                  <SwitchConvoIcon
                    onClick={handleSwitchConvo}
                    className="switch-icon"
                    fill="black"
                    width="35"
                    height="35"
                  />
                </div>
              )}
            </>
          )}
        {forwardStatus === EnumForwarding.NOT_FORWARDED &&
          forwardingRequested &&
          !confirmed && (
            <div
              className={`pb-3 container text-center ${
                showCard ? "slide-in" : "slide-out"
              }`}
            >
              {loading && (
                <>
                  <Spinner
                    className="mt-4"
                    animation="grow"
                    variant="primary"
                  />
                  <p>Even kijken wie beschikbaar is ⌛</p>
                </>
              )}

              {!loading && (
                <>
                  <div className="close-icon" onClick={handleClose}>
                    <CrossIcon fill="#000" width="20px" />
                  </div>
                  <h4 style={{ color: secondaryColor }} className="pt-3">
                    {companyName}
                  </h4>
                  <p style={{ color: secondaryColor }}>
                    Beschikbare medewerkers
                  </p>
                  <hr />
                  {availableUsers.length === 0 ? (
                    <>
                      <p>Op dit moment is er niemand beschikbaar.</p>
                      <br />
                      <small>
                        U kunt ons ook bereiken op:{" "}
                        <a href={`mailto:${companyEmail}`}>
                          <u>{companyEmail}</u>
                        </a>
                      </small>
                    </>
                  ) : (
                    <div className={`row ${showCard ? "visible" : "hidden"}`}>
                      {availableUsers.slice(0, 3).map((user, index) => (
                        <div
                          key={index}
                          className="available-user col-md"
                          onClick={() => handleUserChange(user)}
                        >
                          <div className="avatar-header text-center">
                            <span className="avtr avtr-companyuser">
                              <img
                                src={company_icon}
                                alt="avatar"
                                className={`avatar-image mt-3 ${
                                  selectedUser &&
                                  selectedUser.email === user.email
                                    ? "selected"
                                    : ""
                                }`}
                              />
                            </span>
                          </div>
                          <div className="user-details mt-1">
                            <b>{user.first_name}</b>
                            <br />
                            <span className={`role ${user.role.toLowerCase()}`}>
                              {user.role === "ADMIN"
                                ? "Eigenaar"
                                : "Klantenservice"}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          )}

        {forwardStatus === EnumForwarding.FORWARD_REQUESTED &&
          forwardingRequested && (
            <div
              className={`pb-3 container text-center "slide-in" : "slide-out"
}`}
            >
              <>
                <Spinner className="mt-4" animation="grow" variant="primary" />
                <p>
                  Wachten tot dat <b>{selectedUser?.first_name}</b> je verzoek
                  accepteert⌛
                </p>
              </>
            </div>
          )}
        {forwardStatus === EnumForwarding.REJECTED && confirmed && (
          <>
            <p>Forwarding rejected, try again or talk to our AI bot.</p>
            <div className="header-right" title="Verbinding verbreken">
              <CrossIcon
                onClick={() => handleCancelForward()}
                className="switch-icon"
                fill="red"
                width="35"
                height="35"
              />
            </div>
          </>
        )}
        {forwardStatus === EnumForwarding.IS_FORWARDED && (
          <>
            <div
              className={`header-left ${confirmed ? "slide-in" : "slide-out"}`}
            >
              <div className="avatar-header">
                <span className="avtr">
                  <img
                    className="selected"
                    src={avatarPlaceHolder}
                    alt="botAvatar"
                  />
                </span>
              </div>
              <span className="employee-name">
                <b>
                  {selectedUser?.first_name} {selectedUser?.last_name}
                </b>{" "}
                <br />
              </span>
            </div>
            {/* <div className="header-right" title="Verbinding verbreken">
              <CrossIcon
                onClick={() => handleCancelForward()}
                className="switch-icon"
                fill="red"
                width="35"
                height="35"
              />
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default AvailableUsers;
