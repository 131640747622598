import React, { useEffect, useState } from "react";
import "../bootstrap.scss";
import { useWebSocket } from "../context/WebSocketContext";
import "../css/ChatbotSkeleton.scss";
import { CrossIcon, MinimizeIcon, SendIcon } from "../helpers/SvgIcons";
import {
  availableCompanyUsers,
  closeConversation,
} from "../services/WidgetServices";
import AvailableUsers from "./AvailableUsers";
import WelcomeModal from "./WelcomeModal";
import InteractionStarter from "./InteractionStarter";
import ChatButton from "./ChatButton";
import { isMobile } from "./helpers";
import RobotIcon from "../utils/icons/robot-solid.svg";

function ChatbotSkeleton({
  chatBotID,
  preloadedData,
  sessionIdState,
  setSessionIdState,
}) {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [interactionStarted, setInteractionStarted] = useState(false);

  const [gpt_model] = useState(preloadedData[0]?.gpt_model);
  const [primaryColor] = useState(preloadedData[0]?.color_primary);
  const [secondaryColor] = useState(preloadedData[0]?.color_secondary);
  const [buttonColor] = useState(preloadedData[0]?.color_button);
  const [employeeName] = useState(preloadedData[0]?.employeeName);
  const [welcomeMessage] = useState(preloadedData[0]?.welcomeMessage);
  const [content] = useState(preloadedData[0]?.content);
  const [companyID] = useState(preloadedData[0]?.companyID);
  const [companyEmail] = useState(preloadedData[0]?.company_email);
  const [companyName] = useState(preloadedData[0]?.companyName);
  const [companyNumber] = useState(preloadedData[0]?.company_phone);
  const [department] = useState(preloadedData[0]?.department);

  const [conversations, setConversations] = useState([]);
  const [API_KEY] = useState(preloadedData[0]?.apiKey);
  const [avatarUrl] = useState(preloadedData[0]?.avatar);
  const [company_icon] = useState(preloadedData[0]?.company_icon);

  const [sessionStats, setSessionStats] = useState({
    sessionId: sessionStorage.getItem("uniqueSessionId"),
    messageCount: 0,
  });
  const [systemMessage, setSystemMessage] = useState({
    role: "system",
    content: content,
  });

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [forwardingRequested, setForwardingRequested] = useState(false);
  const [isForwarded, setIsForwarded] = useState(false);
  const [forwarded_to, setIsForwarded_to] = useState(
    selectedUser?.email || companyEmail
  );

  const socket = useWebSocket();

  useEffect(() => {
    const fetchCompanyUsers = async () => {
      try {
        const responseData = await availableCompanyUsers(companyID);

        if (responseData.success && Array.isArray(responseData.data)) {
          const { data } = responseData;
          setCompanyUsers(data);
        } else {
          console.error("Received invalid data format:", responseData);
        }
      } catch (error) {
        console.error("Error fetching company users:", error);
      }
    };
    fetchCompanyUsers();
  }, [companyID]);

  useEffect(() => {
    if (!socket) return;

    const handleWebSocketMessages = (event) => {
      try {
        const receivedData = JSON.parse(event.data);
        if (
          receivedData.type === "conversationData" &&
          Array.isArray(receivedData.data)
        ) {
          const sortedConversations = receivedData.data.sort(
            (a, b) => new Date(b.started_at) - new Date(a.started_at)
          );
          setConversations(sortedConversations);
        } else {
          console.error(
            "Received data is not in the expected format:",
            receivedData
          );
        }
      } catch (error) {
        console.error("Error parsing WebSocket data:", error);
      }
    };

    socket.onmessage = handleWebSocketMessages;

    return () => {
      socket.onmessage = null;
    };
  }, [socket]);

  useEffect(() => {
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    document.documentElement.style.setProperty(
      "--secondary-color",
      secondaryColor
    );
    document.documentElement.style.setProperty("--color-button", buttonColor);
  }, [primaryColor, secondaryColor, buttonColor]);

  useEffect(() => {
    let sessionId = sessionStorage.getItem("uniqueSessionId");
    if (!sessionId) {
      sessionId = Date.now() + "_" + Math.random().toString(36).substr(2, 9);
      sessionStorage.setItem("uniqueSessionId", sessionId);
      setSessionStats((prevStats) => ({
        ...prevStats,
        sessionId: sessionId,
      }));
    }
  }, []);

  useEffect(() => {
    const currentConvo = conversations.find(
      (convo) => convo.sessionId === sessionIdState
    );
    setCurrentConversation(currentConvo);
  }, [conversations, sessionIdState]);

  const handleUserChange = (user) => {
    setSelectedUserName(user.first_name);
    setIsForwarded_to(user.email);
    setSelectedUser(user.email);
  };

  const openWelcomeModal = () => {
    setShowWelcomeModal(true);
  };

  const closeWelcomeModal = () => {
    setShowWelcomeModal(false);
  };

  const sendSessionStats = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/convoStats`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sessionId: sessionStats.sessionId,
            messageCount: sessionStats.messageCount,
          }),
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to send session statistics.");
      }
    } catch (error) {
      console.error("Error sending session statistics:", error);
    }
  };

  useEffect(() => {
    if (isChatbotOpen) {
      const newSessionId = Date.now().toString();

      const botMessages = messages.filter(
        (message) => message.sender !== "user"
      );
      const botMessagesCount = botMessages.length;

      setSessionStats((prevStats) => ({
        ...prevStats,
        messageCount: botMessagesCount,
      }));

      sendSessionStats(botMessagesCount, newSessionId);
    }
  }, [isChatbotOpen, messages]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/configBychatBotID/${chatBotID}/${sessionStats?.sessionId}`
    );
    setSystemMessage({
      role: "system",
      content: content,
    });
    setMessages((prevMessages) => [
      {
        ...prevMessages[0],
        message: welcomeMessage,
      },
      ...prevMessages.slice(1),
    ]);
  }, [
    chatBotID,
    content,
    isChatbotOpen,
    sessionStats?.sessionId,
    welcomeMessage,
  ]);

  useEffect(() => {
    const currentConvo = conversations.find(
      (convo) => convo.sessionId === sessionIdState
    );

    if (currentConvo) {
      const updatedMessages = currentConvo.conversation_data.map((msg) => {
        let sender = "";
        if (msg.role === "user") {
          sender = "user";
        } else if (msg.role === "assistant") {
          sender = "assistant";
        } else {
          sender = "remote worker";
        }
        return {
          message: msg.content,
          sender: sender,
        };
      });

      setMessages(updatedMessages);
    }
  }, [conversations, sessionIdState]);

  useEffect(() => {
    openWelcomeModal();
  }, []);

  const updateConversation = async (sessionId, updatedConversationData) => {
    const conversationData = updatedConversationData.map((message) => ({
      role: message.sender !== "user" ? "assistant" : "user",
      content: message.message,
    }));

    try {
      await fetch(
        `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/msg/api/updateConversation/${forwarded_to}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sessionId: sessionId,
            conversationData: conversationData,
          }),
        }
      );
    } catch (error) {
      console.error("Error updating conversation:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (message.trim() === "") return;

    // Add new user message to local state
    const userMessage = {
      message: message,
      sender: "user",
    };

    const updatedMessages = [...messages, userMessage];

    // Update local state immediately to show the user's message
    setMessages(updatedMessages);

    const apiMessages = updatedMessages.map((msg) => ({
      role: msg.sender === "user" ? "user" : "assistant",
      content: msg.message,
    }));

    const apiRequestBody = {
      chatBotID: chatBotID,
      chatMessages: apiMessages,
      systemMessage: systemMessage,
      gpt_model: gpt_model,
      API_KEY: API_KEY,
      content: content,
      forward: isForwarded ? 1 : 0,
      forwarded_to: forwarded_to,
      sessionId: sessionIdState,
    };

    setIsTyping(true);
    setMessage("");

    // Send the user's message to the backend
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/msg/api/process-message/${forwarded_to}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiRequestBody),
        }
      );

      if (!response.ok) {
        throw new Error("API call failed.");
      }

      // Wait for OpenAI response
      const data = await response.json();

      const newMessages = [
        ...updatedMessages,
        {
          message: data.message,
          sender: employeeName,
        },
      ];

      // Update conversation on backend and then update conversations state
      await updateConversation(sessionIdState, newMessages);
      setConversations((prevConversations) => {
        const updatedConversations = [...prevConversations];
        const conversationIndex = updatedConversations.findIndex(
          (convo) => convo.sessionId === sessionIdState
        );
        if (conversationIndex !== -1) {
          updatedConversations[conversationIndex].conversation_data =
            newMessages.map((msg) => ({
              role: msg.sender !== "user" ? "assistant" : "user",
              content: msg.message,
            }));
        }
        return updatedConversations;
      });

      // Update local state with OpenAI response
      setMessages(newMessages);
    } catch (error) {
      console.error("Error in processing message:", error);
    } finally {
      setIsTyping(false);
    }
  };

  const toggleChatbot = () => {
    closeWelcomeModal();
    if (!isChatbotOpen) {
      initiateConvo();
      console.log('Sending message to parent: chatbot-open');
      window.parent.postMessage('chatbot-open', '*'); // Notify parent page that chatbot is open
    } else {
      console.log('Sending message to parent: chatbot-close');
      window.parent.postMessage('chatbot-close', '*'); // Notify parent page that chatbot is closed
    }
    setIsChatbotOpen((prevState) => !prevState);
  };
  
  const initiateConvo = async () => {
    let sessionId = sessionStorage.getItem("uniqueSessionId");
    setSessionIdState(sessionId);
    if (!sessionId) {
      sessionId = Date.now() + "_" + Math.random().toString(36).substr(2, 9);
      sessionStorage.setItem("uniqueSessionId", sessionId);
      setSessionIdState(sessionId);
      setSessionStats((prevStats) => ({
        ...prevStats,
        sessionId: sessionId,
      }));
    }

    setSessionStats((prevStats) => ({
      ...prevStats,
      sessionId: sessionId,
    }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/initiateConvo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ chatBotID, sessionId }),
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to initialize the conversation.");
      }
    } catch (error) {
      console.error("Error initializing the conversation:", error);
    }
  };

  const closeChatbot = () => {
    setShowConfirmModal(true);
    setIsForwarded(false);
  };

  const handleClose = () => {
    setShowConfirmModal(false);
    setIsForwarded(false);
  };

  const clearChatSession = async () => {
    try {
      await closeConversation(sessionIdState);
      setInteractionStarted(false);
      setIsForwarded(false);
    } catch (error) {
      console.error("Error handling conversation:", error);
    }

    setMessages([
      {
        message: "Hallo!",
        sentTime: "paar seconden geleden",
        sender: "bot",
      },
    ]);
    setSessionStats({
      sessionId: null,
      messageCount: 0,
    });
    sessionStorage.removeItem("uniqueSessionId");
    setShowConfirmModal(false);
    toggleChatbot();
    setForwardingRequested(false);
  };

  function formatMessage(message) {
    const urlPattern = /(\()?(https?:\/\/[^\s]+)(\))?/g;
    const boldPattern = /\*\*(.*?)\*\*/g;

    // Apply bold formatting
    message = message.replace(boldPattern, "<strong>$1</strong>");

    // Recognize URLs, make them clickable, and place them on a new line with a dash
    message = message.replace(
      urlPattern,
      (match, p1, url, p2) =>
        ` <br />- <a href="${url}" target="_blank" style="color: #00FF00;">${url}</a><br /> `
    );

    // Handle subitems marked with " - "
    message = message.split(" - ").join("<br />- ");

    const lines = message.split(/(\d+\.\s)/).filter(Boolean);
    let formattedMessage = lines[0].trim() + "<br />";

    for (let i = 1; i < lines.length; i += 2) {
      let line = `${lines[i]} ${lines[i + 1].trim()}`;
      formattedMessage += line + "<br />";
    }

    return formattedMessage;
  }

  return (
    <>
      <WelcomeModal
        showWelcomeModal={showWelcomeModal}
        setShowWelcomeModal={setShowWelcomeModal}
        welcomeMessage={welcomeMessage}
      />
      <div className="my-chatbot">
        <div className={`botIcon ${isChatbotOpen ? "showBotSubject" : ""}`}>
          {(!isMobile || !isChatbotOpen) && (
            <>
              <div className={`chatbot-container robolCss`}>
                <ChatButton
                  isChatbotOpen={isChatbotOpen}
                  setIsChatbotOpen={setIsChatbotOpen}
                  setShowWelcomeModal={setShowWelcomeModal}
                  company_icon={company_icon}
                  setSessionIdState={setSessionIdState}
                  setSessionStats={setSessionStats}
                  chatBotID={chatBotID}
                />
              </div>
            </>
          )}

          {isChatbotOpen && (
            <>
              <div className="Layout Layout-open Layout-expand Layout-right">
                {!interactionStarted && (
                  <InteractionStarter
                    companyNumber={companyNumber}
                    companyName={companyName}
                    companyEmail={companyEmail}
                    company_icon={company_icon}
                    setIsChatbotOpen={setIsChatbotOpen}
                    setInteractionStarted={setInteractionStarted}
                    toggleChatbot={toggleChatbot}
                  />
                )}
                {interactionStarted && (
                  <div className="Messenger_messenger">
                    <div className="Messenger_header">
                      <div className="header-content">
                        <span
                          className={"chat_minimize_icon"}
                          onClick={() => {
                            toggleChatbot();
                          }}
                        >
                          <MinimizeIcon
                            fill="black"
                            className="mr-1"
                            width="20px"
                          />
                        </span>
                        <span
                          className="chat_close_icon"
                          onClick={() => {
                            closeChatbot();
                          }}
                        >
                          <CrossIcon fill="white" width="20px" />
                        </span>

                        {showConfirmModal && (
                          <div className="chat_modal">
                            <div className="chat_modalcontent">
                              <p>
                                Weet je zeker dat je deze gesprek wilt
                                afsluiten?
                              </p>
                              <button
                                className="customBtnSecondary w-25"
                                onClick={clearChatSession}
                              >
                                Ja
                              </button>
                              <button
                                className=" customBtnPrimary w-25"
                                onClick={handleClose}
                              >
                                Nee
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <AvailableUsers
                      company_icon={company_icon}
                      companyEmail={companyEmail}
                      forwardStatus={currentConversation?.forward}
                      sessionId={sessionIdState}
                      setForwardingRequested={setForwardingRequested}
                      secondaryColor={secondaryColor}
                      availableUsers={companyUsers}
                      companyName={companyName}
                      onChange={handleUserChange}
                      setIsForwarded={setIsForwarded}
                      forwardingRequested={forwardingRequested}
                      avatarUrl={avatarUrl}
                      employeeName={employeeName}
                      department={department}
                    />
                    <div className="Messenger_content">
                      <div className="Messages">
                        <div className="Messages_list">
                          {messages.map((msg, index) => (
                            <div
                              key={index} // Ensure unique key for each message
                              className={`msg ${
                                msg.sender === "user" ? "user" : "remote-worker"
                              }`}
                            >
                              {msg.sender !== "user" && (
                                <span className="bot-avatar gradient-avatar">
                                  <img
                                    src={avatarUrl}
                                    alt="robotIcon"
                                    className="robot-icon"
                                  />
                                </span>
                              )}

                              <span
                                className={`${
                                  msg.sender === "user"
                                    ? "userResponseText slide-from-left"
                                    : "responsText slide-from-right"
                                }`}
                                dangerouslySetInnerHTML={{
                                  __html: formatMessage(msg.message),
                                }}
                              ></span>
                            </div>
                          ))}

                          {!isTyping && (
                            <div className="timestamp">
                              {new Date().toLocaleTimeString()}
                            </div>
                          )}
                        </div>
                      </div>

                      <form id="messenger" onSubmit={handleSubmit}>
                        <div className="Input Input-blank">
                          <input
                            name="msg"
                            className="Input_field"
                            placeholder="Bericht versturen..."
                            value={message}
                            onChange={handleMessageChange}
                          />
                          <button
                            type="submit"
                            className="Input_button Input_button-send"
                          >
                            <div className="Icon">
                              <SendIcon />
                            </div>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ChatbotSkeleton;
