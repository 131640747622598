import React from "react";
import "../css/TypingLoader.scss";

export const TypicLoader = () => {
  return (
    <section class="dots-container">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </section>
  );
};
