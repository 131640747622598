// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots-container {
  display: flex;
}

.text {
  color: black;
}

.dot {
  height: 7px;
  width: 7px;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 10px;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }
  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}`, "",{"version":3,"sources":["webpack://./src/css/TypingLoader.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,UAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,0CAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI;IACI,qBAAA;IACA,yBAAA;IACA,4CAAA;EACN;EAEE;IACI,qBAAA;IACA,yBAAA;IACA,6CAAA;EAAN;EAGE;IACI,qBAAA;IACA,yBAAA;IACA,4CAAA;EADN;AACF","sourcesContent":[".dots-container {\r\n    display: flex;\r\n}\r\n\r\n.text{\r\n    color: black;\r\n}\r\n\r\n.dot {\r\n    height: 7px;\r\n    width: 7px;\r\n    margin-left: 10px;\r\n    margin-top: 10px;\r\n    border-radius: 10px;\r\n    animation: pulse 1.5s infinite ease-in-out;\r\n}\r\n\r\n.dot:last-child {\r\n    margin-right: 0;\r\n}\r\n\r\n.dot:nth-child(1) {\r\n    animation-delay: -0.3s;\r\n}\r\n\r\n.dot:nth-child(2) {\r\n    animation-delay: -0.1s;\r\n}\r\n\r\n.dot:nth-child(3) {\r\n    animation-delay: 0.1s;\r\n}\r\n\r\n@keyframes pulse {\r\n    0% {\r\n        transform: scale(0.8);\r\n        background-color: #b3d4fc;\r\n        box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);\r\n    }\r\n\r\n    50% {\r\n        transform: scale(1.2);\r\n        background-color: #6793fb;\r\n        box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);\r\n    }\r\n\r\n    100% {\r\n        transform: scale(0.8);\r\n        background-color: #b3d4fc;\r\n        box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
